module.exports = {
    "all": [
        '1/10/2022',
        '1/17/2022',
        '3/21/2022',
        '5/30/2022',
        '6/6/2022',
        '8/15/2022',
        '8/22/2022',
        '10/17/2022',
        '10/24/2022',
        '1/9/2023',
        '1/16/2023',
        '3/20/2023'
    ],
    "default": [
        '1/10/2022',
        '3/21/2022',
        '5/30/2022',
        '8/15/2022',
        '10/17/2022',
        '1/9/2023',
        '3/20/2023'
    ],
    "education": [
        '1/17/2022',
        '3/21/2022',
        '6/6/2022',
        '8/22/2022',
        '10/24/2022',
        '1/16/2023',
        '3/24/2023'
    ],
    "counseling": [
        
    ]
}