module.exports = [
   {
      "id": "webster-start-date",
      "type": "start-date",
      "default": true,
      "exclude": [
         "/apply/",
         "/thank-you/"
      ]
   },
]